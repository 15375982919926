import { history } from '../../helpers/history';
import { userServices } from '../../services/userServices';
import { userConstants } from '../constants/userConstants';


const signInRequest = () => {
  return {
    type: userConstants.SIGNIN_REQUEST
  }
}

const signIn = (address: string) => async (dispatch: any) => {
  await dispatch(signInRequest());
  const user = await userServices.signIn(address);
  
  if (user !== null) {
    dispatch({
      type: userConstants.SIGNIN_SUCCESS,
      payload: address
    });
    history.push("/");
  } else {
    localStorage.removeItem('user');
    return dispatch({
      type: userConstants.SIGNIN_FAILED,
      payload: "Jobcoin Address doesn't exist."
    })
  }
}

const logout = () => {
  userServices.logout();
  history.push("/");
  return {
    type: userConstants.LOGOUT
  }
}

export {
  signIn,
  logout
}