import { addressServices } from '../../services/addressServices';
import { addressConstants } from '../constants/addressContants';

const getAddressRequest = () => {
  return {
    type: addressConstants.GET_ADDRESS_REQUEST
  }
}

const getAddress = (address: string | null) => async (dispatch: any) => {
  await dispatch(getAddressRequest());
  try {
    const addressRes = await addressServices.getAddress(address);
    console.log(addressRes)
    await dispatch({
      type: addressConstants.GET_ADDRESS_SUCCESS,
      payload: addressRes
    })
  } catch (error) {
    await dispatch({
      type: addressConstants.GET_ADDRESS_FAILED,
      payload: error
    })
  }
}

const sendJobcoinRequest = () => {
  return {
    type: addressConstants.SEND_ADDRESS_REQUEST
  }
}

const sendJobcoin = (
  toAddress: string, 
  fromAddress: string | null, 
  amount: string
  ) => async (dispatch: any) => {
    await dispatch(sendJobcoinRequest());
    try {
      const res = await addressServices.sendJobcoin(toAddress, fromAddress, amount);
      await dispatch({
        type: addressConstants.SEND_ADDRESS_SUCCESS,
        payload: res
      });
      if(res.status === "OK") {
        await dispatch(getAddress(fromAddress));
      }
    } catch (error) {
      dispatch({
        type: addressConstants.SEND_ADDRESS_FAILED,
        payload: error
      })
    }
}

export {
  getAddress,
  sendJobcoin
}