import React from "react";
import { 
  Button, 
  CardContent, 
  makeStyles, 
  TextField, 
  Theme, 
  Typography 
} from '@material-ui/core';
import DashboardWidgetCard from '../../components/DashboardWidgetCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { useState } from 'react';
import { sendJobcoin } from '../../redux/actions/addressActions';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem'
  },
  errorCaption: {
    color: 'red'
  }
}));

function DashboardSendWidget () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const isSending = useSelector((state: RootState) => state.address.isSending);
  const sendStatus = useSelector((state: RootState) => state.address.sendStatus);
  const [destinationAddress, setDestinationAddress] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [errorAddress, setErrorAddress] = useState<string>("");
  const [errorAmount, setErrorAmount] = useState<string>("");

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault(); 
    if (!destinationAddress) return setErrorAddress("Destination Address required.");
    if (!amount) return setErrorAmount("Amount is required.");
    setErrorAddress("");
    setErrorAmount("");
    dispatch(sendJobcoin(destinationAddress, user, amount));
  }

  return (
   <DashboardWidgetCard title="Send Jobcoin">
     <CardContent className={classes.cardContent}>
       <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            error={errorAddress.length > 0}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="destination-address"
            label="Destination Address"
            value={destinationAddress}
            onChange={(evt) => setDestinationAddress(evt.target.value)}
            disabled={isSending}
          />
          {errorAddress && (
            <Typography className={classes.errorCaption} variant="caption">{errorAddress}</Typography>
          )}
          <TextField
            error={errorAmount.length > 0}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="amount-to-send"
            label="Amount to Send"
            value={amount.replace(/[^0-9\\.]+/g, '')}
            onChange={(evt) => setAmount(evt.target.value)}
            disabled={isSending}
          />
          {errorAddress && (
            <Typography className={classes.errorCaption} variant="caption">{errorAmount}</Typography>
          )}
          {sendStatus && sendStatus !== "OK" && (
            <Alert severity="error">{sendStatus}</Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSending}
          >
            <Typography>
              Send Jobcoins
            </Typography>
          </Button>
        </form>
     </CardContent>
   </DashboardWidgetCard>
  )
};

export default DashboardSendWidget;