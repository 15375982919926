import React from 'react';
import { Container, Grid, makeStyles, Theme } from '@material-ui/core';
import DashboardHeader from './DashboardHeader';
import DashboardBalanceWidget from './DashboardBalanceWidget';
import DashboardSendWidget from './DashboardSendWidget';
import DashboardChart from './DashboardChart';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from '../../redux/actions/addressActions';
import { RootState } from '../../redux/rootReducer';

const useStyles = makeStyles((theme: Theme) => ({
  dashboardBody: {
    marginTop: theme.spacing(4),
    minHeight: "calc(100vh - 80px)"
  },
  gridContainer: {
    minHeight: "calc(100vh - 80px)"
  }
}));

function Dashboard() {
  const classes= useStyles();
  const dispatch = useDispatch();
  const addressInformation = useSelector((state: RootState) => state.address.data);
  const isLoading = useSelector((state: RootState) => state.address.isLoading);

  useEffect(() => {
    const address = localStorage.getItem('user');
    dispatch(getAddress(address));
  } , [dispatch]);
  
  return (
    <Container
      maxWidth={false}
      disableGutters
      style={{
        minHeight: '100vh'
      }}
      >
      <DashboardHeader />
      <Container
        maxWidth={false}
        className={classes.dashboardBody}
      >
        <Grid className={classes.gridContainer} container spacing={5}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DashboardBalanceWidget 
                  balance={addressInformation?.balance}
                  isLoading={isLoading}
                  />
              </Grid>
              <Grid item xs={12}>
                <DashboardSendWidget />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={9}>
            <DashboardChart 
              transactions={addressInformation?.transactions}
              isLoading={isLoading}
              />
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}

export default Dashboard;