import React from 'react';
import { Container, CssBaseline, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import Routes from './Routes';

const theme = createTheme({
  overrides: {
    MuiContainer: {
      'maxWidthXl': {
        maxWidth: '80%'
      }
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth={false}
        disableGutters
        style={{
          minHeight: "100vh"
        }}
        >
          <Routes />
        </Container>
    </ThemeProvider>
  );
}

export default App;
