import { addressServices } from './addressServices';

const signIn = async (address: string) => {
  const user = await addressServices.getAddress(address);

  if (user.transactions.length > 0) {
    localStorage.setItem("user", address);
    return address;   
  } else {
    localStorage.removeItem('user');
    return null;
  }
}

const logout = () => {
  localStorage.removeItem('user');
  window.location.reload();
}

export const userServices = {
  signIn,
  logout
}