import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Box, Card, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../redux/actions/userActions';
import { RootState } from '../../redux/rootReducer';
import { userConstants } from '../../redux/constants/userConstants';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem'
  },
  error: {
    color: 'red'
  }
}));

const Footer = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Made by '}
      <Link color="inherit" href="https://www.cyryllgalon.com/" target="_blank">
        Cyryll Galon
      </Link>{' '}
    </Typography>
  );
}

function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [address, setAddress] = useState<string>("");
  const signInFailed = useSelector((state: RootState) => state.user.error);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    if (!address) {
      return dispatch({
        type: userConstants.SIGNIN_FAILED,
        payload: "Jobcoin address is required."
      })
    }
    dispatch(signIn(address))
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(evt.target.value);
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box mt={5}>
          <img src="https://i.nuuls.com/5rj5A.png" alt="jobcoin_image" />
        </Box>
        <Card className={classes.card}>
          <Typography component="h1" variant="h5" align="center">
            Welcome! Sign In With Your Jobcoin Address
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              error={signInFailed.show}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="jobcoin-address"
              label="Jobcoin Address"
              autoFocus
              value={address}
              onChange={handleChange}
            />
            {signInFailed.show && (
              <Typography 
                className={classes.error} 
                variant="caption"
              >
                {signInFailed.message}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
            >
              Sign In
            </Button>
          </form>
        </Card>
      </div>
      <Box mt={8}>
        <Footer />
      </Box>
    </Container>
  );
}

export default SignIn;