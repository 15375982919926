import { Reducer } from 'redux';
import Address from '../../models/Address';
import { addressConstants } from '../constants/addressContants';

interface AddressState {
  isLoading: boolean;
  isSending: boolean;
  data: Address | null;
  error: any;
  sendStatus: any;
}


const initialState: AddressState = {
  isLoading: false,
  isSending: false,
  data: null,
  error: null,
  sendStatus: ""
} 

interface ActionType {
  type: String;
  payload: any;
}

export const addressReducer: Reducer<AddressState, ActionType> = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case addressConstants.GET_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case addressConstants.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      }
    case addressConstants.GET_ADDRESS_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload
      }
    case addressConstants.SEND_ADDRESS_REQUEST:
      return {
        ...state,
        isSending: true,
      }
    case addressConstants.SEND_ADDRESS_SUCCESS:
      return {
        ...state,
        isSending: false,
        sendStatus: action.payload.status,
        error: null
      }
    case addressConstants.SEND_ADDRESS_FAILED:
      return {
        ...state,
        isSending: false,
        sendStatus: "",
        error: action.payload
      }
    default:
      return state;
  }
}
