import React from "react";
import { CardContent, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import DashboardWidgetCard from '../../components/DashboardWidgetCard';

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150
  }
}));

type Prop = {
  balance?: string
  isLoading?: boolean
}

function DashboardBalanceWidget ({balance, isLoading}: Prop) {
  const classes = useStyles();

  return (
   <DashboardWidgetCard title="Jobcoin Balance">
     <CardContent className={classes.cardContent}>
       <Typography component="div" variant="h4">
          {isLoading ? <CircularProgress /> : balance}
       </Typography>
     </CardContent>
   </DashboardWidgetCard>
  )
};

export default DashboardBalanceWidget;