import axios from 'axios';
import { userServices } from './userServices';

const HttpClient = axios.create({
  baseURL: 'https://jobcoin.gemini.com',
  timeout: 80000,
  headers: {
    'Content-Type': 'application/json',
  },
});

HttpClient.interceptors.response
  .use(
    (response) => {
      return response.data
    },
    (error) => {
      if (error.response.status === 401) {
        userServices.logout();
        return Promise.reject(error.response);
      } else if (error.response.status === 422) {
        return Promise.reject({status: "You have insufficient funds!"});
      }
      return Promise.reject('[HttpClient] - Network Error');
    },
  );

export default HttpClient;
