import React from "react";
import { Card, CardHeader, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontSize: "1.5rem"
  },
  cardHeader: {
    borderBottom: '1px solid rgb(0 0 0 / 7%);'
  }
}));

type Props = {
  title: string,
  children: JSX.Element
}

function DashboardWidgetCard ({ title, children }: Props) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={title}
        className={classes.cardHeader}
        classes={{
          title: classes.title
        }}
      />
      {children}
    </Card>
  )
};

export default DashboardWidgetCard;