import React from 'react';
import { Card, CardContent, CircularProgress, makeStyles, Theme,  Typography } from '@material-ui/core';
import { LineChart, Line, XAxis, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import Transaction from '../../models/Transaction';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(1),
  },
  content: {
    width: "100%",
    height: "100%",
  },
  tooltip: {
    padding: theme.spacing(1),
    background: "#17232c",
    borderRadius: "5px",
    color: "#64e492",
    textAlign: 'center'
  },
  tooltipBalance: {
    fontSize: '1.1rem'
  }, 
  tooltipDate: {
    color: "#747b80",
    fontSize: '0.9rem'
  },
  tooltipContent: {
    color: "#747b80",
  },
  tooltipToFrom: {
    fontSize: '0.8rem',
    color: '#d4d4d4'
  }
}));

const CustomTooltip = ({ payload, label, active }: any) => {
  const classes = useStyles();
  if (active) {
    return (
     <div className={classes.tooltip}>
      <Typography className={classes.tooltipBalance}>
          Balance: {payload[0].value}
      </Typography>
      <Typography className={classes.tooltipToFrom}>
        {payload[0].payload.toAddress && `To: ${payload[0].payload.toAddress}`}
      </Typography>
      <Typography className={classes.tooltipToFrom}>
        {payload[0].payload.fromAddress && `From: ${payload[0].payload.fromAddress}`}
      </Typography>
      <Typography className={classes.tooltipToFrom}>
        {payload[0].payload.amount && `Amount: ${payload[0].payload.amount}`}
      </Typography>
      <Typography className={classes.tooltipDate}>{moment(label).format("MMMM DD h:mma")}</Typography>
     </div>
    )
  }

  return null;
}

const CustomLegend = () => {
  return (
    <Typography variant="h5" align="center">
      Jobcoin Transaction History
    </Typography>
  )
}

type Prop = {
  transactions?: Transaction[]
  isLoading?: boolean
}

function DashboardChart ({transactions, isLoading}: Prop) {
  const classes = useStyles();
  const user = useSelector((state: RootState) => state.user.user);

  const calucateTr = (transactions?: Transaction[]) => {
    const data: any = [];
    let balance = 0;
    let newTransaction:any = {};

    if (transactions) {
      transactions.forEach((transaction, idx) => {
        if (transaction.toAddress === user) {
          balance += Number(transaction.amount);
        } else {
          balance -= Number(transaction.amount);
        }
        newTransaction = {
          ...transaction,
          balance: balance,
        }

        data.push(newTransaction);
      });
    }

    return data;
  }

  function formatXAxis(tickItem: string) {
    return moment(tickItem).format('MMM DD YYYY')
  }

  if (isLoading) return <div>loading</div>

  const calculated = calucateTr(transactions);

  return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          {isLoading ? <CircularProgress /> : (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart 
                data={calculated} 
                margin={{
                  right: 60,
                  left: 60,
                  bottom: 5,
                }}
                >
                <Line 
                  name="Jobcoin Transaction History" 
                  dataKey="balance" 
                  stroke="#54dcfc" 
                  strokeWidth={3} 
                  dot={false}
                  activeDot={{
                    stroke: "#64e492",
                    strokeWidth: 5
                  }}
                  />
                <XAxis 
                  dataKey="timestamp"
                  interval={5}
                  tickFormatter={formatXAxis}
                  />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" height={36} content={<CustomLegend />} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </CardContent>
      </Card>
  )
};

export default DashboardChart;