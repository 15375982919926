import EndpointsEnum from '../enums/EndpointsEnum';
import HttpClient from './HttpClient';

const getAddress = async (address: string | null) => {
  try {
    const res = await HttpClient.get(`${EndpointsEnum.addresses}${address}`);
    return res;
  } catch (error) {
    return error
  }
}

const sendJobcoin = async (
  toAddress: string, 
  fromAddress: string | null, 
  amount: string
) => {
  const data = {
    toAddress,
    fromAddress,
    amount
  }
  try {
    const res = await HttpClient.post(`${EndpointsEnum.transactions}`, JSON.stringify(data));
    return res;
  } catch(error) {
    return error;
  }
}

export const addressServices = {
  getAddress,
  sendJobcoin
}