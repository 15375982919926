import { Reducer } from 'redux';
import { userConstants } from '../constants/userConstants';

interface UserState {
  signedIn: boolean;
  user: string | null;
  isLoading: boolean;
  error: {
    show: boolean,
    message: string | null;
  };
}

const user = localStorage.getItem("user");

const initialState: UserState = user ? {
  signedIn: true,
  user: user,
  isLoading: false,
  error: {
    show: false,
    message: ""
  },
} : {
  signedIn: false,
  user: null,
  isLoading: false,
  error: {
    show: false,
    message: ""
  },
}

interface ActionType {
  type: String;
  payload: any;
}

export const userReducer: Reducer<UserState, ActionType> = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case userConstants.SIGNIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          show: false,
          message: ""
        },
      }
    case userConstants.SIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        signedIn: true,
        error: {
          show: false,
          message: ""
        },
        user: action.payload
      }
    case userConstants.SIGNIN_FAILED:
      return {
        ...state,
        isLoading: false,
        user: null,
        error: {
          show: true,
          message: action.payload
        }
      }
    default:
      return state;
  }
}
