import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/userActions';
import { AccountCircle, ExitToApp } from '@material-ui/icons';
import { RootState } from '../../redux/rootReducer';

const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: "#10101a",
	},
	title: {
		flexGrow: 1
	},
  accountBadge: {
    marginRight: theme.spacing(1)
  },
  icon: {
    width: 50,
    marginRight: theme.spacing(1)
  }
}));

function DashboardHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const handleLogout = () => {
    dispatch(logout());
  }

  return (
    <AppBar className={classes.appBar} position="relative">
			<Toolbar>
        <Box>
          <img className={classes.icon} src="https://i.nuuls.com/TMSxa.png" alt="jobcoin" />
        </Box>
        <Typography variant="h5"  className={classes.title}>
          Jobcoin Sender
        </Typography>
        <Badge>
          <AccountCircle className={classes.accountBadge} />
          <Typography>
            {user}
          </Typography>
        </Badge>
        <Button color="inherit" onClick={handleLogout}>
          <ExitToApp />
        </Button>
			</Toolbar>
		</AppBar>
  )
};

export default DashboardHeader;