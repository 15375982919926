import React from 'react';
import { Redirect, Route,  Router, Switch } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import SignIn from './containers/Signin/Signin';
import Dashboard from './containers/Dashboard/Dashboard';
import { history } from './helpers/history';
import { useSelector } from 'react-redux';
import { RootState } from './redux/rootReducer';

const PrivateRoute = ({ component: Component, ...options }: any) => {
  const isSignedIn = useSelector((state: RootState) => state.user.signedIn);

  if (!isSignedIn) {
    return <Redirect to={{ pathname: "/signin" }} />
  } else {
    return <Component {...options} />;
  }
};

const Routes = () => (
  <ErrorBoundary>  
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} />
        <Route exact path="/signin" component={SignIn} />
        <Route path="*" render={() => (<h1>ERROR 404 Page doesnt exist</h1>)} />
      </Switch>
    </Router>
  </ErrorBoundary>
);

export default Routes;