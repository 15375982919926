import { combineReducers } from "redux";
import { addressReducer } from './reducers/addressReducer';
import { userReducer } from './reducers/userReducer';

export const rootReducer = combineReducers({
  user: userReducer,
  address: addressReducer
});

export type RootState = ReturnType<typeof rootReducer>
